footer
  padding: 100px 150px 100px 150px
  color: #cbd6e2
  background-color: #33475b
  position: relative
  font-weight: 500
  font-size: 1.0rem

  .padded-item
    margin: 0 50px 0 50px
  
  .icon-links
    position: absolute
    bottom: 75px
    right: 150px
 
    a
      color: inherit
      text-decoration: none
      margin-left: 20px

      &.yt:hover
        color: red

      &.fb:hover
        color: #4267b2
      
      &.ig:hover
        color: #d6249f

  .container
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center

    .email-link
      color: inherit

      &:hover
        text-decoration: underline
        color: white

@media screen and (max-width: 500px)
  footer
    padding: 60px 30px 60px 30px

    .padded-item
      margin: 0 0 0 30px

      p
        font-size: smaller

@media screen and (max-width: 900px)
  footer
    padding-top: 60px
    padding-bottom: 60px

    .icon-links
      position: initial
      max-width: fit-content
      margin: auto
      margin-top: 30px
