.content-container
  box-sizing: border-box
  padding: 100px
  color: #33475b

  .border
    border-bottom: 1px solid #06a4be

  h1
    font-size: 40px
    font-weight: 700
    margin-bottom: 30px
  
  p
    font-size: 17px
  
  q
    width: 90%
    display: block
    padding: 20px 20px 20px 50px
    border-left: 5px solid #33475b
    background-color: #f5f8fa
    border-radius: 5px
    font-style: italic
    margin: auto

@media screen and (max-width: 500px)
  .content-container
    padding: 50px
    max-width: 100vw

    h1
      font-size: 40px
      font-weight: 700
      margin-bottom: 30px

    p
      font-size: 17px

    q
      padding: 20px 15px 20px 15px
      margin: 0
      width: 100%
