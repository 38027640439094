.admin-layout
  min-height: 100vh
  background-color: #f5f8fa
  background-image: url("https://www.transparenttextures.com/patterns/black-thread-light.png")

  .admin-header
    background-color: #323641
    border-radius: 0

    .title
      color: #FFFFFF

  .main-content
    padding-left: 2%
    padding-right: 2%
    padding-top: 2%

    .container
      overflow-x: scroll
      width: 100%

      .header
        float: left

      .main-btn
        float: right
        margin-bottom: 10px
        color: #FFFFFF
        background-color: #323641

.label
  display: block
  margin: 0 0 .28571429rem 0
  color: rgba(0,0,0,.87)
  font-size: .92857143em
  font-weight: 700
  text-transform: none

// Kind of hacky - accessing class names from the rte (rich text editor) package 
.ui.form .RichTextEditor__root___2QXK-
  margin-bottom: 10px

  .public-DraftEditor-content
    min-height: 200px

  select  
    background: initial
    padding: initial
    padding: 4px 35px 4px 12px
