.error-page-content
  padding: 15vh 15vw 10vh 15vw
  background-color: #f5f8fa
  background-image: url("https://www.transparenttextures.com/patterns/black-thread-light.png")
  height: 70vh

  .message-container
    padding: 30px 50px 30px 50px
    background-color: white
    border-radius: 10px
    box-shadow: 5px 7px 13px 0px
    display: flex
    justify-content: flex-start
    align-items: center