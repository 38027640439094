.articles-container
  padding: 7vh 13vw 10vh 13vw
  background-color: #f5f8fa
  background-image: url("https://www.transparenttextures.com/patterns/black-thread-light.png")
  margin-top: 50px

.article-header
  min-height: 20vh
  width: 100vw
  padding: 10vh 10vw 5vh 10vw
  color: white
  background-image: url("https://www.transparenttextures.com/patterns/black-thread-light.png")

  h2
    font-family: 'Bungee Outline', cursive
    font-size: -webkit-xxx-large

.article-container
  background-color: #f5f8fa

  .action-list
    display: inline-block
    float: left
    padding: 10px
    margin-left: 15vw
    margin-top: 5vh !important
    position: sticky
    top: 100px

    .action-item
      margin-bottom: 5px


  .article-content
    margin: 0 20vw 0 20vw
    padding: 50px

    .article-body
      font-size: 16px
      line-height: 1.6
      font-family: 'Merriweather', serif

    .author
      color: grey
      margin-top: 30px
      font-size: 17px
      font-family: 'Courgette', cursive

@media screen and (max-width: 600px)
  .article-container
    .article-content
      margin: 0
      padding: 50px

    .action-list
      display: block
      float: right
      position: sticky
      top: calc(100vh - 70px)
      right: 30px

      .item .ui.circular.button
        box-shadow: 6px 8px 11px grey !important
        margin-bottom: 20px
