.form-container
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  padding: 120px 0 20px 0

  background-color: #f5f8fa
  background-image: url("https://www.transparenttextures.com/patterns/black-thread-light.png")

.divider
  background-color: #ccf2f9
  min-height: 10px
