@import './admin.sass'
@import './header.sass'
@import './footer.sass'
@import './content.sass'
@import './articles.sass'
@import './form.sass'
@import './events.sass'
@import './gallery.sass'
@import './error.sass'

body
  margin: 0
  font-family: "AvenirNext", "Helvetica Neue", Helvetica,Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.page-content
  margin-top: 67px
