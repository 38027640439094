.events-container
  padding: 5vh 0 5vh 0
  background-color: #f5f8fa
  background-image: url("https://www.transparenttextures.com/patterns/black-thread-light.png")

.fb-page.fb_iframe_widget
  margin: 100px auto 50px auto
  width: 500px
  max-width: 100vw
  display: block
