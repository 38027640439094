.menu-header
  padding: 0 150px 0 150px
  font-size: 1.2rem !important
  box-shadow: 0px 5px 22px black !important
  background-color: #f5f8fa !important

  .menu-item
    color: #33475b !important
    font-weight: bold !important
  
  .menu-logo .menu-item
      margin-left: 20px

@media screen and (max-width: 767px)
  .menu-header
    padding: 0
    max-width: 100vw
    box-sizing: border-box

    .wrapper
      display: flex

    .menu-logo
      max-width: 200px !important
    
    .menu-dropdown
      margin-left: calc(100vw - 305px)
      max-width: 100px !important
